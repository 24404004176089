/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import { Link } from 'gatsby';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import Container from '../../components/Container';
import Image from '../../components/Image';
import {
  BodyTextBig,
  FlexWrap,
  TextWrap,
  ImageWrap,
  SectionTitle,
  WhiteSection,
  SectionLabel,
} from '../../components/Global';
import constants from '../../constants';

const TemplatesSection = () => (
  <WhiteSection
    id={constants.DOM_ID.TEMPLATES}
    textAlign="left"
  >
    <Container>
      <FlexWrap direction="column-reverse">
        <ImageWrap>
          <Image name="product_template.png" alt="templates" />
        </ImageWrap>
        <TextWrap width="45%" className="mobile-text-center">
          <SectionLabel mt="40px">Templates</SectionLabel>
          <SectionTitle mb="16px">
            Don’t reinvent the wheel
          </SectionTitle>
          <BodyTextBig mb="16px">
            Create templates for your commonly used tasks and processes
            to save your team time and effort.
          </BodyTextBig>
          <BodyTextBig>
            <Link to="/help/articles/61000165240/">Learn more about Templates</Link>
          </BodyTextBig>
        </TextWrap>
      </FlexWrap>
    </Container>
  </WhiteSection>
);

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
TemplatesSection.propTypes = {
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default TemplatesSection;
